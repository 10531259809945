<i18n>
[
    "login__phoneSignIn--title",
    "login__phoneSignIn--subtitleWeb",
    "login__phoneJoinNow--cta",
    "account__phone--label",
    "global__next",
]
</i18n>

<template>
    <div class="c-phone-login">
        <LoginContainer
            :title="$t('login__phoneSignIn--title')"
            :subtitle="$t('login__phoneSignIn--subtitleWeb')"
            :onSubmit="onSubmit"
            :errorText="serviceErrorMessage"
            :isLoaded="isLoaded"
            :submitButton="{
                class: 'u-spacer--5',
                text: $t('global__next'),
            }"
        >
            <template #form-field>
                <PhoneInput
                    v-model="phoneNumber"
                    :shouldAutoFocus="true"
                    name="phoneNumber"
                    class="u-spacer--5"
                />
            </template>
            <template #after-form>
                <BaseMarkdown
                    :source="$t('login__phoneJoinNow--cta')"
                    class="o-text--caption u-text--gray"
                />
            </template>
        </LoginContainer>
    </div>
</template>

<script>

import { get } from 'lodash-es';
import { mapActions } from 'vuex';

import { LOGIN_MODULE_NAME, SEND_VERIFICATION_SMS } from '~coreRoutes/login/js/login-store';

import { stripNonNumbersFromString } from '~coreModules/core/js/utils';

import { genericRouteNames } from '~coreModules/core/js/router-constants';

import LoginContainer from '~coreRoutes/login/components/LoginContainer.vue';
import PhoneInput from '~coreModules/core/components/ui/form-elements/text-inputs/PhoneInput.vue';
import BaseMarkdown from '~coreModules/core/components/ui/BaseMarkdown.vue';

export default {
    name: 'LoginPhoneForm',
    components: {
        LoginContainer,
        PhoneInput,
        BaseMarkdown,
    },
    data() {
        return {
            phoneNumber: '',
            serviceErrorMessage: '',
            isLoaded: false,
        };
    },
    computed: {
        formattedPhoneNumber() {
            const phoneNumber = stripNonNumbersFromString(this.phoneNumber);
            return `+1${phoneNumber}`;
        },
    },
    methods: {
        ...mapActions(LOGIN_MODULE_NAME, {
            sendVerificationSms: SEND_VERIFICATION_SMS,
        }),
        onSubmit() {
            return this.sendVerificationSms(this.formattedPhoneNumber)
                .then(() => {
                    this.isLoaded = true;
                    this.$router.push({ name: genericRouteNames.login.phone.verify });
                })
                .catch((error) => {
                    this.serviceErrorMessage = get(error, 'data.message', '');
                });
        },
    },
};
</script>

<style lang="scss">

</style>
