<i18n>
[
    "address__phone--label",
    "onboarding__accountCreate--phonePlaceholder",
]
</i18n>

<template>
    <BaseInput
        ref="input"
        v-model="inputValue"
        :rules="{
            required: true,
            regex: /\([0-9]{3}\)\s[0-9]{3}\-[0-9]{4}/
        }"
        :mask="{
            mask: '(###) ###-####',
            preprocessor: val => val.replace(/^[1]/g, ''),
        }"
        :labelText="$t('address__phone--label')"
        :autocomplete="'off'"
        :helperText="$t('onboarding__accountCreate--phonePlaceholder')"
        :inputType="'tel'"
        :isRequired="isRequired"
        :shouldAutoFocus="shouldAutoFocus"
        name="phoneNumber"
    />
</template>

<script>
import BaseInput from '~coreModules/core/components/ui/form-elements/text-inputs/BaseInput.vue';

export default {
    name: 'PhoneInput',
    components: { BaseInput },
    props: {
        initialTelephone: {
            type: String,
            default: '',
        },
        isRequired: {
            type: [Boolean, String],
            default: true,
        },
        shouldAutoFocus: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            inputValue: this.initialTelephone || '',
        };
    },
    methods: {
        focus() {
            this.$refs.input.focus();
        },
    },
};
</script>

<style lang="scss">

</style>
